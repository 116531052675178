import { createContext, useContext, useState } from 'react';
import invariant from 'tiny-invariant';
import type { Dispatch, SetStateAction } from 'react';

// *****************************************
// *
// * LayoutDataContext
// *
// *****************************************

export type LayoutDataContextProps = {
  categoriesTree: LayoutDataContextPropsCategoryTreeItem[];
};

interface LayoutDataContextPropsCategoryTreeItem {
  hash: string;
  name: string;
  subItems: LayoutDataContextPropsCategoryTreeItem[] | null;
}

const LayoutDataContext = createContext<LayoutDataContextProps>(null as any);
LayoutDataContext.displayName = 'LayoutDataContext';

// *****************************************
// *
// * LayoutPricesTypeContext
// *
// *****************************************

type LayoutPricesTypeContextProps = {
  pricesType: PricesType;
  setPricesType: Dispatch<SetStateAction<PricesType>>;
};

type PricesType = 'B2B' | 'B2BF' | 'B2C';

const LayoutPricesTypeContext = createContext<LayoutPricesTypeContextProps>(null as any);

LayoutPricesTypeContext.displayName = 'LayoutPricesTypeContext';

// *****************************************
// *
// * LayoutMobileNavigationContext
// *
// *****************************************

type LayoutMobileNavigationContextProps = {
  mobileNavigationOpened: boolean;
  setMobileNavigationOpened: Dispatch<SetStateAction<boolean>>;
};

const LayoutMobileNavigationContext = createContext<LayoutMobileNavigationContextProps>(null as any);

LayoutMobileNavigationContext.displayName = 'LayoutMobileNavigationContext';

// *****************************************
// *
// * Context
// *
// *****************************************

type LayoutContextProps = {
  loginDialogOpened: boolean;
  openLoginDialog: () => void;
  setLoginDialogOpened: Dispatch<SetStateAction<boolean>>;
};

const LayoutContext = createContext<LayoutContextProps>(null as any);

LayoutContext.displayName = 'LayoutContext';

// *****************************************
// *
// * LayoutContextProvider
// *
// *****************************************

export function LayoutContextProvider(props: { children: React.ReactNode; layoutData: LayoutDataContextProps }) {
  const [mobileNavigationOpened, setMobileNavigationOpened] = useState(false);
  const [loginDialogOpened, setLoginDialogOpened] = useState(false);
  const [pricesType, setPricesType] = useState<PricesType>('B2C');

  function openLoginDialog() {
    setLoginDialogOpened(true);
  }

  return (
    <LayoutDataContext.Provider value={props.layoutData}>
      <LayoutPricesTypeContext.Provider value={{ pricesType, setPricesType }}>
        <LayoutContext.Provider
          value={{
            loginDialogOpened,
            openLoginDialog,
            setLoginDialogOpened,
          }}
        >
          <LayoutMobileNavigationContext.Provider value={{ mobileNavigationOpened, setMobileNavigationOpened }}>
            {props.children}
          </LayoutMobileNavigationContext.Provider>
        </LayoutContext.Provider>
      </LayoutPricesTypeContext.Provider>
    </LayoutDataContext.Provider>
  );
}

// *****************************************
// *
// * useLayoutContext
// *
// *****************************************

export function useLayoutContext() {
  return useContext(LayoutContext);
}

// *****************************************
// *
// * useMobileNavigationState
// *
// *****************************************

export function useMobileNavigationState(): [isOpened: boolean, setOpened: Dispatch<SetStateAction<boolean>>] {
  const ctx = useContext(LayoutMobileNavigationContext);
  invariant(ctx != null, "'useMobileNavigationState' is used outside of 'LayoutContextProvider'.");
  return [ctx.mobileNavigationOpened, ctx.setMobileNavigationOpened];
}

// *****************************************
// *
// * useMobileNavigationOpenHandle
// *
// *****************************************

export function useMobileNavigationOpenHandle(): Dispatch<SetStateAction<boolean>> {
  const ctx = useContext(LayoutMobileNavigationContext);
  invariant(ctx != null, "'useMobileNavigationOpenHandle' is used outside of 'LayoutContextProvider'.");
  return ctx.setMobileNavigationOpened;
}

// *****************************************
// *
// * usePricesType
// *
// *****************************************

export function usePricesType() {
  const ctx = useContext(LayoutPricesTypeContext);
  invariant(ctx != null, "'usePricesType' is used outside of 'LayoutContextProvider'.");
  return ctx.pricesType;
}

// *****************************************
// *
// * usePricesTypeState
// *
// *****************************************

export function usePricesTypeState(): [value: PricesType, setPricesType: Dispatch<SetStateAction<PricesType>>] {
  const ctx = useContext(LayoutPricesTypeContext);
  invariant(ctx != null, "'usePricesTypeState' is used outside of 'LayoutContextProvider'.");
  return [ctx.pricesType, ctx.setPricesType];
}

// *****************************************
// *
// * useLayoutData
// *
// *****************************************

export function useLayoutData() {
  const ctx = useContext(LayoutDataContext);
  invariant(ctx != null, "'useLayoutData' is used outside of 'LayoutContextProvider'.");
  return ctx;
}
