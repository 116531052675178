import { Inter } from '@next/font/google';
import { GoogleTagManager } from '@next/third-parties/google';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';
import type { LayoutDataContextProps } from '@/components/layout-context';
import { LayoutContextProvider } from '@/components/layout-context';
import fetchJson from '@/lib/fetch-json';
import type { I18nMessageConfig } from '@/lib/i18n-messages-load';
import SEO_DEFAULT_CONFIG from '@/lib/seo-config';
import trpc from '@/lib/trpc';
import type { GetStaticPropsContext, GetStaticPropsResult, NextPage, PreviewData } from 'next';
import type { AppProps } from 'next/app';
import 'nprogress/nprogress.css';
import type { ParsedUrlQuery } from 'querystring';
import type { ReactElement, ReactNode } from 'react';
import 'swiper/swiper.min.css';
import '../styles/globals.css';

// Page loging indicator configuration
NProgress.configure({ showSpinner: false });

// Initialize Application base font
const baseFont = Inter({
  display: 'swap',
  style: ['normal'],
  subsets: ['latin'],
  weight: ['100', '400', '500', '600', '700', '900'],
});

// Each page will implement this type
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

// Each page `getStaticProps` will implement this type
export type NextGetStaticProps<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
> = (
  context: GetStaticPropsContext<Q, D>,
) => Promise<GetStaticPropsResult<NextPagePropsWithAppOwn<P>>> | GetStaticPropsResult<NextPagePropsWithAppOwn<P>>;

// Helper type to enhance a page props with app own props.
type NextPagePropsWithAppOwn<P = {}> = P & MyAppOwnProps;

// Own propetries of application
type MyAppOwnProps = {
  intlMessages: I18nMessageConfig;
  layoutData: LayoutDataContextProps;
  //
  // ! If used with relay uncomment next line.
  // [RELAY_INITIAL_RECORDS_PROP]?: Record<string, any>;
};

type MyAppProps = AppProps<MyAppOwnProps> & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps, router }: MyAppProps) {
  const { defaultLocale = 'cs', locale = 'cs' } = router;
  const {
    //
    // !!! If used with relay uncomment next line.
    //  __RELAY_INITIAL_RECORDS__: relayInitialRecords,
    //
    // Compiled JSON object with translated INTL messages.
    intlMessages,
    // Data passed to `LayoutContextProvider`
    layoutData,
    // Props passed to Page Component
    ...componentProps
  } = pageProps;

  //
  // ! If used with relay uncomment next line.
  // const relayEnvironment = useRelayAppEnvironment(relayInitialRecords);

  // Page loading indicator binding.
  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };

    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  // Page custom layout
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
        <>
          <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID} />

          <script
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){
            dataLayer.push(arguments);
          }
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
          `,
            }}
          />
        </>
      )}
      <Script src='/scripts/leadyTracker.js' />
      {/* Application optimalized font. */}
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        html {
          height: 100%;
          font-family: ${baseFont.style.fontFamily}, sans-serif;
          font-size: 16px;
        }
      `}</style>

      <DefaultSeo {...SEO_DEFAULT_CONFIG} />
      <SWRConfig
        value={{
          fetcher: fetchJson,
          onError: (err) => {
            console.error(err);
          },
        }}
      >
        {/*
          ! If used with relay uncomment next line.
          */}
        {/* <RelayEnvironmentProvider environment={relayEnvironment}> */}
        <IntlProvider defaultLocale={defaultLocale} locale={locale} messages={intlMessages}>
          {/* <CommerceCheckoutProvider> */}
          <LayoutContextProvider layoutData={layoutData}>
            {/*
              Content of page
            */}
            {getLayout(<Component {...componentProps} />)}

            {/*
              Application toaster.

              For settings details see https://react-hot-toast.com/docs.
            */}
            <Toaster position='top-right' reverseOrder={false} />
          </LayoutContextProvider>
          {/* </CommerceCheckoutProvider> */}
        </IntlProvider>
        {/* </RelayEnvironmentProvider> */}
      </SWRConfig>
    </>
  );
}

export default trpc.withTRPC(MyApp);
