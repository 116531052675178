import type { DefaultSeoProps } from 'next-seo';

export const SEO_DEFAULT_TITLE = 'Blyth';
const titleTemplate = `%s | ${SEO_DEFAULT_TITLE}`;

const SEO_DEFAULT_CONFIG: DefaultSeoProps = {
  defaultTitle: SEO_DEFAULT_TITLE,
  // openGraph: {
  //   locale: 'en_IE',
  //   siteName: 'SiteName',
  //   type: 'website',
  //   url: 'https://www.url.ie/',
  // },
  titleTemplate,
  // twitter: {
  //   cardType: 'summary_large_image',
  //   handle: '@handle',
  //   site: '@site',
  // },
};

export default SEO_DEFAULT_CONFIG;
